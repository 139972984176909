<template>

<!-- 政府管理页面 -->
  <div class="container">
    <div class="header-container">
      <div class="add-container">
        <router-link :to="{name: 'managementGovernmentAdd'}">
          <el-button class="addUser-button">
            新增
          </el-button>
        </router-link>
      </div>
    </div>
    <div class="body-container">
      <el-table
        :data="AdministratorGetGovernmentListData"
        stripe
        @selection-change="handleSelectionChange"
        style="width: 100%">
        <el-table-column
          type="selection"
         >
        </el-table-column>
        <el-table-column
          prop="sysName"
          label="政府名称">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="政府电话">
        </el-table-column>
        <el-table-column
          prop="provinceName"
          label="省">
        </el-table-column>
        <el-table-column
          prop="cityName"
          label="市">
        </el-table-column>
        <el-table-column
          prop="areaName"
          label="区">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <router-link :to="{name: 'managementGovernmentEdit', params: {item: scope.row }}">
              <el-button
                size="mini"
                type="text"
                >编辑</el-button>
            </router-link>
            <el-divider direction="vertical"></el-divider>
            <el-button
              @click="showDel([scope.row.id])"
              size="mini"
              type="text"
              >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
      <el-button @click="delSelect">
        批量删除
      </el-button>
      <pagination :current-page="show.startPage" :total="show.totalPage"  @currentChange="editStartPage" @sizeChange="sizeChange"></pagination>
    </div>
  </div>
</template>
<script>
// import storage from '@/globals/storage/index.js'
import AdministratorService from '@/globals/service/management/Administrator.js'
export default {
  data () {
    return {
      AdministratorGetGovernmentListDataSelect: [],
      AdministratorGetGovernmentListData: [],
      show: {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    sizeChange (val) {
      this.show.pageSize = val
      this.getData()
    },
    delSelect () {
      if (this.AdministratorGetGovernmentListDataSelect.length <= 0) {
        this.$message({
          type: 'info',
          message: '暂未选中任何政府'
        })
        return
      }
      this.showDel(this.AdministratorGetGovernmentListDataSelect)
    },
    handleSelectionChange (val) {
      this.AdministratorGetGovernmentListDataSelect = val.map(item => {
        return item.id
      })
    },
    del (id) {
      AdministratorService.AdministratorDelGovernment({ list: id }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getData()
      })
    },
    editStartPage (index) {
      this.show.startPage = index
      this.getData()
    },
    showDel (id) {
      this.$confirm('此操作将永久删除该政府, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    getData () {
      AdministratorService.AdministratorGetGovernmentList(this.show).then(res => {
        this.AdministratorGetGovernmentListData = res.data.governmentListVoList.map(item => {
          for (const key in item) {
            if (item[key] === null) item[key] = '-'
          }
          return item
        })
        this.show.totalPage = res.data.totalPage
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 120px;
    .seek-container{
      display: flex;
      align-items: center;
      .seek-section{
        border: 1px solid #34BBEC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner{
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search{
          color: #999999;
        }
      }
      .seek-button{
        width: 80px;
        background-color: #34BBEC;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container{
      .addUser-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
  .body-container{
    flex: 1;
  }
  .footer-container{
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button{
      margin-right: 160px;
    }
  }
}
</style>
